













































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import { SelectItem } from '../../../../dsl/atoms/Select'
import { StructureConfigurable } from '../../../../support/mixins'

import { ShipmentRegion } from '../../../shared/services/dictionary'
import { ShipmentRegionsMixin } from '../../../shared/mixins/shipment-regions.mixin'

import {
  ChangeAddressFormFieldsData,
  ChangeAddressFormFieldsProps
} from './ChangeAddressFormFields.contracts'
import {
  CHANGE_ADDRESS_FORM_FIELDS_CONFIG_MAP,
  CHANGE_ADDRESS_FORM_FIELDS_KEY,
  ChangeAddressFormFieldsConfig
} from './ChangeAddressFormFields.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ChangeAddressFormFields>({
  name: 'ChangeAddressFormFields',
  created () {
    this.config = this.getComponentConfig(CHANGE_ADDRESS_FORM_FIELDS_KEY, { ...CHANGE_ADDRESS_FORM_FIELDS_CONFIG_MAP })
    this.retrieveShipmentRegions(this.shouldLoadShipmentRegions)
    this.setModel()

    if (!this.showCompanyFields) {
      this.model.company = null
    }
  }
})
export class ChangeAddressFormFields extends Mixins(ShipmentRegionsMixin, StructureConfigurable) {
  @Prop({ type: Array, required: true })
  public countries!: ChangeAddressFormFieldsProps['countries']

  @Prop({ type: Object, required: false, default: () => ({}) })
  public errors!: ChangeAddressFormFieldsProps['errors']

  @Prop({ type: Object, required: true })
  protected readonly formData!: ChangeAddressFormFieldsData

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCompany?: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public readonly isEditable!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly noPerson!: boolean

  @Prop({ type: Array, required: false, default: () => [] })
  public fieldsFixedDisabled!: Array<string>

  @Prop({ type: Boolean, required: false, default: true })
  public showCompanyFields!: boolean

  protected config!: ChangeAddressFormFieldsConfig

  public model: ChangeAddressFormFieldsData = {} as ChangeAddressFormFieldsData
  public regionCode?: string = ''

  public get shouldLoadShipmentRegions (): boolean {
    return this.getConfigProperty('shouldLoadShipmentRegions')
  }

  public get shouldHaveCountryForm (): boolean {
    return this.getConfigProperty<boolean>('shouldHaveCountryForm')
  }

  public get shouldChangePostalCodeLayout (): boolean {
    return this.getConfigProperty<boolean>('shouldChangePostalCodeLayout')
  }

  /**
   * Determines whether field should be always disabled
   */
  public isFixedDisabled (field: string): boolean {
    if (this.fieldsFixedDisabled.length === 0) {
      return false
    }

    return this.fieldsFixedDisabled.includes(field)
  }

  /**
   * Gets error for specified fields.
   */
  public getErrors (key: string): string[] | undefined {
    if (!this.errors || !this.errors[key]) {
      return
    }

    return this.errors[key].map(e => String(this.$t(e)))
  }

  public get countriesOptions (): SelectItem[] {
    return this.countries.map((country) => {
      return {
        label: country.label,
        value: country.code
      }
    })
  }

  public get hasShipmentRegions (): boolean {
    return Array.isArray(this.shipmentRegions) && this.shipmentRegions.length > 0
  }

  public setModel () {
    this.model = { ...this.formData }

    if (this.hasShipmentRegions && Object.prototype.hasOwnProperty.call(this.model, 'region')) {
      this.regionCode = (this.model.region as ShipmentRegion).code
    }
  }

  public onRegionUpdate (value: string): void {
    this.regionCode = value

    if (this.shipmentRegions && Object.prototype.hasOwnProperty.call(this.model, 'region')) {
      this.model = {
        ...this.model,
        region: this.shipmentRegions.find((region) => region.code === this.regionCode)
      }
    }
  }

  public get regionOptions (): Array<SelectItem> | undefined {
    if (!this.hasShipmentRegions) {
      return
    }

    return this.shipmentRegions!.map((region) => {
      return {
        label: region.name,
        value: region.code
      }
    })
  }

  @Watch('model', { deep: true })
  protected onModelChange (value: ChangeAddressFormFieldsData): void {
    this.$emit('update:errors')
    this.$emit('update:formData', value)
  }

  @Watch('model.countryCode')
  protected onCountryCodeUpdate (code: string): void {
    this.retrieveShipmentRegionsByLocaleId(code)
  }
}

export default ChangeAddressFormFields
