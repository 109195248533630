
import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '../../../support'
import { ISiteService, SiteServiceType } from '../../../contexts'

import { CountryData, DictionaryServiceType, IDictionaryService, ShipmentRegion } from '../services/dictionary'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ShipmentRegionsMixin>({
  name: 'ShipmentRegionsMixin'
})
export class ShipmentRegionsMixin extends Vue {
  @Inject(DictionaryServiceType)
  protected readonly dictionaryService!: IDictionaryService

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  public shipmentRegions: Array<ShipmentRegion> | null = null

  public get countriesData (): Array<CountryData> {
    return this.dictionaryService.countries
  }

  /**
   * Retrieves shipment regions for current locale
   * @protected
   */
  protected retrieveShipmentRegions (hasRegionField: boolean): void {
    if (!hasRegionField) {
      return
    }

    const locale = this.siteService.getActiveSite().locale
    const country = this.dictionaryService.countries.find((country) => country.id === locale)

    this.shipmentRegions = country && country.availableRegions ? country.availableRegions : null
  }

  /**
   * Retrieves shipment regions for given locale
   * @protected
   */
  protected retrieveShipmentRegionsByLocaleId (localeId: string): void {
    const country = this.dictionaryService.countries.find((country) => country.id === localeId)
    this.shipmentRegions = country && country.availableRegions ? country.availableRegions : null
  }
}
