
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHANGE_ADDRESS_FORM_FIELDS_KEY = 'ChangeAddressFormFields'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ChangeAddressFormFieldsConfig {
  shouldHaveCountryForm: boolean
  shouldChangePostalCodeLayout: boolean
  shouldLoadShipmentRegions: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHANGE_ADDRESS_FORM_FIELDS_CONFIG_MAP: ChangeAddressFormFieldsConfig = {
  shouldHaveCountryForm: true,
  shouldChangePostalCodeLayout: false,
  shouldLoadShipmentRegions: false
}
